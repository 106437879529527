import { api } from "..";

export const CampaignsService = {
    getCampaigns: (payload: {page: number}) =>
        api.get(`/public/campaigns?page=${payload.page}`).then((data) => data?.data),

    getMediaLibrary: () =>
        api.get(`/media/library`).then((data) => data?.data),

    getScreensList: (payload: {page: number}) =>
        api.get(`public/screen?page=${payload.page}`).then((data) => data?.data),

}