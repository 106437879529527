import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AdminServices } from '../../../services/admin/AdminServices';

const ScreenDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [screen, setScreen] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchScreenDetails();
  }, [id]);

  const fetchScreenDetails = async () => {
    setLoading(true);
    try {
    const response = await AdminServices.getScreenById(id)
      setScreen(response.data);
    } catch (error) {
      console.error('Error fetching screen details:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      <h1>Screen Details</h1>
      {screen && (
        <div>
          <p>Name: {screen.name}</p>
          <p>Status: {screen.status}</p>
          <p>Address: {screen.address.line1}, {screen.address.line2}, {screen.address.city}, {screen.address.state}, {screen.address.country}, {screen.address.zip}</p>
          <p>Resolution: {screen.resolution}</p>
          <p>Tags: {screen.tags.join(', ')}</p>
          <p>Operating Time: {screen.startTime} - {screen.endTime}</p>
          <img src={screen.image} alt={`${screen.name} image`} />
          <video src={screen.video} controls />
        </div>
      )}
    </div>
  );
};

export default ScreenDetails;
