import { api } from "..";

export const AdminServices = {
    getCampaigns: (payload: {page: number}) =>
        api.get(`/public/campaigns?page=${payload.page}`).then((data) => data?.data),

    registerUser:(payload:any) => 
    api.post(`/admin/user/register`,payload).then((data) => data?.data),


    registerScreen:(payload:any) => 
    api.post(`/admin/screen/register`,payload).then((data) => data?.data),


    getUserByEmail:(email:string) =>   api.get(`admin/user/email/${email}`).then((data) => data?.data),

    getUserById:(id:string) =>   api.get(`admin/user/id/${id}`).then((data) => data?.data),

    getScreenList:(pageNumber:number | string) =>   api.get(`public/screen/state/ALL/city/ALL/page/${pageNumber}`).then((data) => data?.data),

    getScreenById:(id:number | string | undefined) =>   api.get(`public/screen/${id}`).then((data) => data?.data),

}