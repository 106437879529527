import { TLayoutConsumerProps } from "./LayoutConsumer.types"
import { useLayout } from "../../hooks/useLayout"
import React, { useEffect } from "react";
import { auth, awaitFirebaseInitialization } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routing/routes";
import { Navbar } from "../../features/Navbar";

export const LayoutConsumer: React.FC<TLayoutConsumerProps> = ({
    path,
    isPrivate,
    navigationMenu = false,
    children
}) => {
    const navigate = useNavigate()
    useLayout(navigationMenu)

    useEffect(() => {
        (async () => await awaitFirebaseInitialization())()
    },[])

    useEffect(() => {
        if(isPrivate && !auth.currentUser) {
            navigate(routes.login)
        }
    },[])

    return (
        <div 
        style={{
            // display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'flex-start',
            backgroundColor: '#EDE7FD',
            height: '100vh',
            overflowY:'auto'
        }}>{navigationMenu && <Navbar />}
        <>{children}</>
        </div>    
    )
}