import { auth } from "../../config/firebase";
import { api } from "..";

interface ISubmitEmailProps {
  email: string;
  password: string;
}

export const AuthService = {

  signOut: () => auth.signOut(),

  submitEmailLogin: (payload: ISubmitEmailProps) =>
    auth.signInWithEmailAndPassword(payload.email, payload.password),

  /* loginUpdate: (payload: { userId: string; type: string }) =>
    api.post("/public/user/login", payload).then((data) => data?.data?.response), */
  
  submitEmailSignUp: (payload: ISubmitEmailProps) =>
    auth.createUserWithEmailAndPassword(payload.email, payload.password),

  confirmResetPassword: (code: string, password: string) =>
    auth.confirmPasswordReset(code, password),
};
