import { call, takeLatest, all, put } from "redux-saga/effects";
import { actionFetchCampaigns, actionFetchCampaignsFailed, actionFetchCampaignsSuccess, actionFetchMediaLibrary, actionFetchMediaLibraryFailed, actionFetchMediaLibrarySuccess, actionFetchScreensList, actionFetchScreensListFailed, actionFetchScreensListSuccess } from "./campaigns.reducer";
import { CampaignsService } from "../../services/campaigns/CampaignsService";
import { TActionGetCampaignsListPayload } from "./campaigns.types";

function* fetchCampaignsList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getCampaigns, {page: payload.page})                       
        yield put(actionFetchCampaignsSuccess({data}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchCampaignsFailed())
    }
}

function* fetchMediaLibrary(): any{
    try {
        const data= yield call(CampaignsService.getMediaLibrary)                       
        yield put(actionFetchMediaLibrarySuccess({data}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchMediaLibraryFailed())
    }
}

function* fetchScreensList({payload}: TActionGetCampaignsListPayload): any{
    try {
        const data= yield call(CampaignsService.getScreensList, {page: payload.page})                       
        yield put(actionFetchScreensListSuccess({data: data.response}))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

export function* campaignsWatcher() {
    yield all([
        takeLatest(actionFetchCampaigns.type, fetchCampaignsList),
        takeLatest(actionFetchMediaLibrary.type, fetchMediaLibrary),
        takeLatest(actionFetchScreensList.type, fetchScreensList)
    ])
}