import  AdminPanel  from "../pages/adminPanel";
import ScreenDetails from "../pages/adminPanel/Screens/ScreenDetails";
import {LoginPage} from "../pages/auth/login/Login";
import { routes } from "./routes";

export type TRouteData = {
    path: string;
    Component: React.FC<any>;
    isPrivate: boolean;
    navigationMenu?: boolean;
    children?: {
        path: string;
        Component: React.FC<any>;
    }[]
}


export const routesData: TRouteData[] = [
    { path: routes.login, Component: LoginPage, isPrivate: false, navigationMenu: false },
    { path: routes.home, Component: LoginPage, isPrivate: false, navigationMenu: false },
    { path: routes.admin, Component: AdminPanel, isPrivate: false, navigationMenu: false },
    { path: routes.screenId, Component: ScreenDetails, isPrivate: false, navigationMenu: false },
]