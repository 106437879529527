import { routes } from "../../routing/routes";

export const enum NAV_ITEM_NAME {
    OVERVIEW = 'Overview',
    SCREENS = 'Screens',
    CLUSTER = 'Cluster',
    MEDIA = 'Media',
    CAMPAIGN = 'Campaign'
}

export type TNavBarItem = {
    icon: string;
    name: NAV_ITEM_NAME;
    active: boolean;
    route: string;
}

export const navbarItem = (activeNav: NAV_ITEM_NAME) => ([
    {
        icon: './icons/overview.svg',
        name: NAV_ITEM_NAME.OVERVIEW,
        active: activeNav === NAV_ITEM_NAME.OVERVIEW,
        route: routes.overview
    },
    // {
    //     icon: './icons/screens.svg',
    //     name: NAV_ITEM_NAME.SCREENS,
    //     active: activeNav === NAV_ITEM_NAME.SCREENS,
    //     route: routes.overview
    // },
    // {
    //     icon: './icons/cluster.svg',
    //     name: NAV_ITEM_NAME.CLUSTER,
    //     active: activeNav === NAV_ITEM_NAME.CLUSTER,
    //     route: routes.overview
    // },
    {
        icon: './icons/media.svg',
        name: NAV_ITEM_NAME.MEDIA,
        active: activeNav === NAV_ITEM_NAME.MEDIA,
        route: routes.media
    },
    {
        icon: './icons/campaign.svg',
        name: NAV_ITEM_NAME.CAMPAIGN,
        active: activeNav === NAV_ITEM_NAME.CAMPAIGN,
        route: routes.campaign
    },
]) as TNavBarItem[]