import axios, { AxiosRequestConfig, AxiosError, AxiosHeaders } from 'axios';
import { auth, awaitFirebaseInitialization } from '../config/firebase';
import { routes } from '../routing/routes';
import { v4 as uuidv4 } from 'uuid';

const UNAUTHORIZED_CODE = 403;
const CLIENT_ID = `${process.env.REACT_APP_CLIENT_ID}`;

let isTokenRefreshing = false;
let requestQueue: RequestQueueType[] = [];
type RequestQueueType = (accessToken: string) => void;

const api = axios.create({
    baseURL: process.env.REACT_APP_GATEWAY_URL,
});

const handleRefreshTokenError = () => {
    localStorage.removeItem('user');
    window.location.href = `${window.location.origin}${routes.login}`;
};

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
        try {
            await awaitFirebaseInitialization();

            const currentUser = auth.currentUser;
            const requestId = uuidv4();

            if (currentUser) {
                const access_token = await currentUser.getIdToken();

                if (access_token) {
                    const headers = new AxiosHeaders({
                        Authorization: `Bearer ${access_token}`,
                        ClientId: CLIENT_ID,
                        RequestID: requestId,
                    });

                    return {
                        ...config,
                        headers: headers,
                    };
                }
            }
            const headers = new AxiosHeaders({
                ClientId: CLIENT_ID,
                RequestID: requestId,
            });

            return {
                ...config,
                headers: headers,
            };
        } catch (error) {
            const requestId = uuidv4();
            const headers = new AxiosHeaders({
                ClientId: CLIENT_ID,
                RequestID: requestId,
            });

            return {
                ...config,
                headers: headers,
            };
        }
    }
);

api.interceptors.response.use(
    (response: any) => response,
    async (error: AxiosError) => {
        const { response } = error;

        await awaitFirebaseInitialization();

        const currentUser = auth.currentUser;

        if (!response || !currentUser) throw error;

        const { status, config } = response;

        if (status === UNAUTHORIZED_CODE && !isTokenRefreshing) {
            isTokenRefreshing = true;

            try {
                try {
                    const access_token = await currentUser.getIdToken(true);
                    const requestId = uuidv4();

                    (api.defaults.headers as any).set(
                        'Authorization',
                        `Bearer ${access_token}`
                    );

                    requestQueue.forEach((request) => request(access_token));
                    requestQueue = [];

                    const headers = new AxiosHeaders({
                        Authorization: `Bearer ${access_token}`,
                        ClientId: CLIENT_ID,
                        RequestID: requestId,
                    });

                    return await api({
                        ...config,
                        headers: headers,
                        baseURL: process.env.REACT_APP_GATEWAY_URL,
                    });
                } catch (refreshTokenError: any) {
                    const refreshStatus = refreshTokenError.response?.status;

                    if (refreshStatus === UNAUTHORIZED_CODE) {
                        handleRefreshTokenError();
                    }

                    throw refreshTokenError;
                }
            } finally {
                isTokenRefreshing = false;
            }
        }

        if (status === UNAUTHORIZED_CODE && isTokenRefreshing)
            return new Promise((resolve) => {
                requestQueue.push((access: string) => {
                    const requestId = uuidv4();

                    const headers = new AxiosHeaders({
                        Authorization: `Bearer ${access}`,
                        ClientId: CLIENT_ID,
                        RequestID: requestId,
                    });

                    resolve(
                        api({
                            ...config,
                            headers: headers,
                            baseURL: process.env.REACT_APP_GATEWAY_URL,
                        })
                    );
                });
            });

        throw error;
    }
);

export { api };