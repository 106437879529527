import { SvgIcon } from "../../components/SvgIcon"
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text"
import { auth } from "../../config/firebase"
import { NAV_ITEM_NAME, TNavBarItem, navbarItem } from "./Navbar.utils"
import styles from './Navbar.module.scss'
import classNames from "classnames"
import {useState } from "react"
import { useNavigate } from "react-router"
import { useLoginSelector } from "../../store/login/useLoginSelectors"

export const NavbarComponent = () => {
    const navigate = useNavigate()
    const [activeNav, setActiveNav] = useState(NAV_ITEM_NAME.OVERVIEW)
    const {userData} = useLoginSelector()

    // useEffect(() => {
    //     if(userData.accountStatus === 'ACTIVE'){
    //         navigate(routes.login)
    //     }
    // },[])

    
    const handleActiveNav = (navData:TNavBarItem ) => {
        setActiveNav(navData.name)
        navigate(navData.route)
    }


    return (
    <div className={styles.navbarWrapper}>
            <img src={'./images/logo_white.svg'} width={135} /> 
            <div className={styles.headerLinks}>
                {navbarItem(activeNav).map((item, index) => (
                    <div key={index} className={classNames(styles.item)}
                    onClick={()=> handleActiveNav(item)}
                    >
                        <SvgIcon 
                        className={classNames({[styles.activeIcon]: item.active})} 
                        size={item.active ? 28 : 14} src={item.icon}  
                        // color={ item.active ? 'white' : 'neutral-30'} 
                        defaultStoke
                        />
                        <Text 
                            variant={TextVariantsEnum.Text_sm} 
                            fontWeight = {FontWeightEnum.Normal}
                            color={ item.active ? 'white' : 'neutral-30'}
                        >
                            {item.name}
                        </Text>
                </div>
                ))}
            </div>   
            <div>
                <Text variant={TextVariantsEnum.Text_sm} color="white">{userData.name}</Text>
            </div>
    </div>
    )
}