import { createAction, createSlice } from '@reduxjs/toolkit';
import { requestStatusEnum } from '../store.types';
import { TActionGetCampaignsListPayload, TActionGetCampaignsListSuccess, TActionGetMediaLibrarySuccess, TActionGetScreensListSuccess, TCampaignsReducer } from './campaigns.types';


const initialState: TCampaignsReducer = {
    campaignsList: {
        data: [],
        status: requestStatusEnum.INITIAL
    },
    currentPage: 0,
    mediaLibrary: {
      data: [],
      status: requestStatusEnum.INITIAL
    },
    selectedMedia: '',
    screensList: {
      data: [],
      status: requestStatusEnum.INITIAL
    },
    selectedScreens:[]
};

const campaignsReducer = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    actionFetchCampaigns(state, { payload }: TActionGetCampaignsListPayload) {
      state.campaignsList.status = requestStatusEnum.PROGRESS;
    },
    actionFetchCampaignsSuccess(state, {payload}: TActionGetCampaignsListSuccess) {
      state.campaignsList.status = requestStatusEnum.SUCCESS;
      state.campaignsList.data = payload.data
    },
    actionFetchCampaignsFailed(state) {
      state.campaignsList.status = requestStatusEnum.FAILED;
    },
    actionFetchMediaLibrary(state) {
      state.mediaLibrary.status = requestStatusEnum.PROGRESS;
    },
    actionFetchMediaLibrarySuccess(state, {payload}: TActionGetMediaLibrarySuccess) {
      state.mediaLibrary.status = requestStatusEnum.SUCCESS;
      state.mediaLibrary.data = payload.data
    },
    actionFetchMediaLibraryFailed(state) {
      state.mediaLibrary.status = requestStatusEnum.FAILED;
    },
    actionSetSelectedMedia(state, {payload}) {
      state.selectedMedia = payload;
    },
    actionFetchScreensList(state, {payload}: TActionGetCampaignsListPayload) {
      state.screensList.status = requestStatusEnum.PROGRESS;
    },
    actionFetchScreensListSuccess(state, {payload}: TActionGetScreensListSuccess) {
      state.screensList.status = requestStatusEnum.SUCCESS;
      state.screensList.data = payload.data
    },
    actionFetchScreensListFailed(state) {
      state.screensList.status = requestStatusEnum.FAILED;
    },
    actionSetSelectedScreen(state, {payload}) {
      state.selectedScreens = payload;
    },
    resetCampaigns() {
      return initialState;
    },
  },
})

export const {
    actionFetchCampaigns,
    actionFetchCampaignsSuccess,
    actionFetchCampaignsFailed,
    actionFetchMediaLibrary,
    actionFetchMediaLibrarySuccess,
    actionFetchMediaLibraryFailed,
    actionSetSelectedMedia,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionSetSelectedScreen,
    resetCampaigns
} = campaignsReducer.actions;

export default campaignsReducer.reducer

