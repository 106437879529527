import { useEffect, useState } from "react"
import { LayoutContext } from "../../hooks/useLayout"
import { TLayoutProviderProps } from "./LayoutProvider.types"
import { awaitFirebaseInitialization } from "../../config/firebase"

export const LayoutProvider = ({ children }: TLayoutProviderProps) => {
    const [isNavigationMenuVisible, setNavigationMenuVisible] = useState(false)

    useEffect(() => {
        (async () => await awaitFirebaseInitialization())()
    }, [])

    return (
        <LayoutContext.Provider value={setNavigationMenuVisible}>
            <main>
                {children}
            </main>
        </LayoutContext.Provider>
    )
}