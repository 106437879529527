import { createAction, createSlice } from '@reduxjs/toolkit';
import { TActionLoginWithEmailPayload, TLoginReducer } from './login.types';
import { requestStatusEnum } from '../store.types';


const initialState: TLoginReducer = {
  form: {
    email: '',
    password: '',
    remember_me: false,
    status: requestStatusEnum.INITIAL
  },
  error: { email: '', password: '' },
  userData: {
    accountStatus: '',
    orgId: '',
    role: '',
    userId: '',
    name: '',
  }
};

const loginReducer = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    actionSubmitLogin(state, { payload }: TActionLoginWithEmailPayload) {
      state.form.status = requestStatusEnum.PROGRESS;
    },
    actionSubmitLoginSuccess(state) {
      state.form.status = requestStatusEnum.SUCCESS;
    },
    actionSubmitLoginFailed(state) {
      state.form.status = requestStatusEnum.FAILED;
    },
    submitLoginError(state, { payload }) {
      state.form.status = requestStatusEnum.FAILED;
      state.error = payload;
    },
    // updateLoginInput(state, { payload }: TUpdateLoginInput) {
    //   state.form[payload.field] = payload.value;
    // },
    // updateLoginCheckbox(state, { payload }: TUpdateLoginCheckbox) {
    //   state.form.remember_me = payload;
    // },
    resetLogin() {
      return initialState;
    },
  },
})

export const {
    actionSubmitLogin,
    actionSubmitLoginSuccess,
    actionSubmitLoginFailed
} = loginReducer.actions;

export default loginReducer.reducer

