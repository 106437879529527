import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button as MuiButton, Typography, Box, Select, MenuItem, FormControl, InputLabel, CircularProgress, Backdrop, Snackbar, Alert } from "@mui/material";
import classes from "./adminPanelForm.module.scss";
import Button from "../Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../Button/button.types";
import { AdminServices } from "../../services/admin/AdminServices";
import { auth } from "../../config/firebase";

type ScreenFormValues = {
  name: string;
  status: string;
  userId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  lat: string;
  lng: string;
  tags: string;
  resolution: string;
  startTime: string;
  endTime: string;
  image: string;
  video: string;
};

const ScreenForm: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<ScreenFormValues>();

  const onSubmit = handleSubmit(async (data) => {
    const parseData = {
      name: data.name,
      status: data.status,
      userId: data?.userId,
      address: {
        line1: data.addressLine1,
        line2: data.addressLine2,
        city: data.city,
        state: data.state,
        country: data.country,
        zip: data.zip,
        lat: data.lat,
        lng: data.lng
      },
      tags: data.tags.split(',').map(tag => tag.trim()),
      resolution: data.resolution,
      startTime: data.startTime,
      endTime: data.endTime,
      image: data.image,
      video: data.video
    };

    setLoading(true);

    try {
        const response = await AdminServices.registerScreen(parseData); // Pass token to the service method

        setLoading(false);
        setSnackbarOpen(true); // Open Snackbar on success
        setModalOpen(true);
      } 
      
    catch (error) {
      setLoading(false);
    }
  });


  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className={classes.container}>
      <h2>Register New Screen</h2>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {/* Form fields here... */}
          <Grid item xs={12}>
            {/*4uQ7wGviQZhWALZS5XdxYUtk3yj1*/}
            <TextField
                {...register("userId", { required: "User ID is required" })}
                label="User Id"
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("name", { required: "Name is required" })}
              label="Screen Name"
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                label="Status"
                defaultValue="ACTIVE"
                {...register("status", { required: "Status is required" })}
              >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("addressLine1", { required: "Address Line 1 is required" })}
              label="Address Line 1"
              variant="outlined"
              fullWidth
              error={!!errors.addressLine1}
              helperText={errors.addressLine1 && errors.addressLine1.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("addressLine2")}
              label="Address Line 2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("city", { required: "City is required" })}
              label="City"
              variant="outlined"
              fullWidth
              error={!!errors.city}
              helperText={errors.city && errors.city.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("state", { required: "State is required" })}
              label="State"
              variant="outlined"
              fullWidth
              error={!!errors.state}
              helperText={errors.state && errors.state.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("country", { required: "Country is required" })}
              label="Country"
              variant="outlined"
              fullWidth
              error={!!errors.country}
              helperText={errors.country && errors.country.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("zip", { required: "Zip Code is required" })}
              label="Zip Code"
              variant="outlined"
              fullWidth
              error={!!errors.zip}
              helperText={errors.zip && errors.zip.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("lat", { required: "Latitude is required" })}
              label="Latitude"
              variant="outlined"
              fullWidth
              error={!!errors.lat}
              helperText={errors.lat && errors.lat.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("lng", { required: "Longitude is required" })}
              label="Longitude"
              variant="outlined"
              fullWidth
              error={!!errors.lng}
              helperText={errors.lng && errors.lng.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register("tags", { required: "Tags are required" })}
              label="Tags (comma separated)"
              variant="outlined"
              fullWidth
              error={!!errors.tags}
              helperText={errors.tags && errors.tags.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="resolution">Resolution</InputLabel>
              <Select
                  labelId="resolution"
                  label="Resolution"
                  defaultValue="P1080"
                  {...register("resolution", { required: "Resolution is required" })}
              >
                <MenuItem value="P1080">1080p</MenuItem>
                <MenuItem value="P720">720p</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("startTime", { required: "Start Time is required" })}
              label="Start Time"
              variant="outlined"
              fullWidth
              error={!!errors.startTime}
              helperText={errors.startTime && errors.startTime.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("endTime", { required: "End Time is required" })}
              label="End Time"
              variant="outlined"
              fullWidth
              error={!!errors.endTime}
              helperText={errors.endTime && errors.endTime.message}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button variant={ButtonVariantEnum.primary} size={ButtonTextSizeEnum.lg} text="Register" onClick={onSubmit} />
              <Button variant={ButtonVariantEnum.ternary} onClick={() => {}} size={ButtonTextSizeEnum.lg} text="Cancel" />
            </div>
          </Grid>
        </Grid>
      </form>

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle style={{ backgroundColor: "#6938EF", color: "#fff" }}>
          <Typography variant="h5" component="div" align="center">
            Screen Registration Successful
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Box p={2} textAlign="center">
            <Typography variant="h6" style={{ color: "#6938EF" }}>
              Your screen has been registered successfully!
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariantEnum.primary} size={ButtonTextSizeEnum.sm} text="Ok" onClick={handleCloseModal} width={80} />
        </DialogActions>
      </Dialog>

      <Backdrop open={loading} style={{ zIndex: 1300, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          Screen registered successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ScreenForm;
