import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { WalletServices } from '../../../services/wallet/WalletService';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EditIcon from '@mui/icons-material/Edit';
import { auth } from '../../../config/firebase';

interface Wallet{
  balance: string;
  id: string;
  txnAmount: string;
  type: string;
  date: string;
  metadata: {
    txnRef: any;
    campaignId: string;
  },
  initiatedBy: string;
}

interface walletInfo {
  balance: string;
  totalPages:number;
  totalElements:number;
  transactions: Array<Wallet>;
}

/*balance
    :
    "400.00"
date
    :
    "2024-10-07T17:38:24"
id
    :
    "1728322703843"
initiatedBy
    :
    "7om3TC6Of1eW5B8u2HUJ35dfhZm1"
metadata
    :
    campaignId
        :
        "1728322678581"
txnRef
    :
    null
        [[Prototype]]
:
Object
txnAmount
    :
    "40.0"
type
    :
    "HOLD"*/

const WalletComponent: React.FC = () => {
  const [walletInfo, setWalletInfo] = useState<walletInfo | null>(null);
  const [wallets, setWallets] = useState<Wallet[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [balance, setBalance] = useState<number | null>(null);
  const [balanceDialogOpen, setBalanceDialogOpen] = useState<boolean>(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
  const [selectedWallet, setSelectedWallet] = useState<walletInfo | null>(null);
  const [amount, setAmount] = useState<string>('');
  const [action, setAction] = useState<'credit' | 'debit'>('credit');
  const [orgId, setOrgId] = useState<string>('');
  const [orgIdInput, setOrgIdInput] = useState<string>('');
  const currentUser = auth.currentUser;

  const fetchWalletHistory = async (orgId: string) => {
    setLoading(true);
    try {
      const response = await WalletServices.getWalletHistory(orgId, 0);
      setWalletInfo(response.response);
    } catch (error) {
      console.error('Error fetching wallet history:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetBalance = async (orgId: string) => {
    try {
      const response = await WalletServices.getWalletBalance(orgId);
      setBalance(response.balance); // assuming response contains balance
      setBalanceDialogOpen(true);
    } catch (error) {
      console.error('Error fetching wallet balance:', error);
    }
  };

  const handleUpdateWallet = async () => {
    if (!selectedWallet) return;

    const payload = {
      orgId,
      amount,
      userId: '',
    };
    try {
      if (action === 'credit') {
        await WalletServices.creditWallet(payload);
      } else {
        await WalletServices.debitWallet(payload);
      }
      fetchWalletHistory(orgId);
      setUpdateDialogOpen(false);
    } catch (error) {
      console.error(`Error ${action}ing wallet:`, error);
    }
  };

  const handleOpenUpdateDialog = (wallet: walletInfo) => {
    setSelectedWallet(wallet);
    setAmount('');
    setAction('credit');
    setOrgId(orgId);
    setUpdateDialogOpen(true);
  };

  const handleFetchWalletHistory = () => {
    fetchWalletHistory(orgIdInput);
    setOrgId(orgIdInput);
  };

  console.log('orgID => ', orgIdInput);

  return (
    <div>
      <TextField
        label="Org ID"
        value={orgIdInput}
        onChange={(e) => setOrgIdInput(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button onClick={handleFetchWalletHistory} disabled={!orgIdInput}>
        Fetch Wallet History
      </Button>
      {walletInfo && (
          <h2>{`Total Balance: ${walletInfo?.balance}`}</h2>
      )}

      {loading ? (
          <CircularProgress/>
      ) : (
        <Grid container spacing={2}>
          {walletInfo?.transactions?.map((transaction) => (
            <Grid item xs={12} sm={6} md={4} key={transaction.id}>
              <Paper elevation={3} style={{padding: 16}}>
                <div>Transaction ID: {transaction.id}</div>
                <div>Balance: {transaction.balance}</div>
                <div>Transaction amount: {transaction?.txnAmount}</div>
                <div>Transaction type: {transaction?.type}</div>
                <IconButton onClick={() => handleGetBalance(orgId)}>
                  <AccountBalanceWalletIcon/>
                </IconButton>
                {transaction.id === walletInfo?.transactions[0]?.id && (
                    <IconButton onClick={() => handleOpenUpdateDialog(walletInfo)}>
                      <EditIcon/>
                    </IconButton>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog open={balanceDialogOpen} onClose={() => setBalanceDialogOpen(false)}>
        <DialogTitle>Wallet Balance</DialogTitle>
        <DialogContent>
          <div>Balance: {balance}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBalanceDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)}>
        <DialogTitle>Update Wallet</DialogTitle>
        <DialogContent>
          <TextField
            label="Org ID"
            value={orgId}
            onChange={(e) => setOrgId(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            select
            label="Action"
            value={action}
            onChange={(e) => setAction(e.target.value as 'credit' | 'debit')}
            fullWidth
            margin="normal"
          >
            <MenuItem value="credit">Credit</MenuItem>
            <MenuItem value="debit">Debit</MenuItem>
          </TextField>
          <TextField
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateWallet}>OK</Button>
          <Button onClick={() => setUpdateDialogOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WalletComponent;
