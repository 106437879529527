import React, { useState } from "react";
import classes from "./adminPanel.module.scss";
import AdminForm from "../../components/AdminForms/AdminForm";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import Button from "../../components/Button/Button";
import { headerButtonsArray, tabsConstants } from "./constans";
import VideosList from "./VideoList";
import UsersList from "./UsersList";
import FormSwitcher from "./Forms";
import WalletComponent from "./Wallet";

const AdminPanel: React.FC = () => {
  const [selectedView, setSelectedView] = useState<string>(tabsConstants.videos);

  const renderBody = (selectedView : string) : JSX.Element=> {
    const tabsComponentMapping : { [key:string]: JSX.Element } = {
        [tabsConstants.videos] : (<div className={classes.listContainer}><VideosList /> </div>),
        [tabsConstants.form] : (<div><FormSwitcher /></div>),
        [tabsConstants.users] : (<div className={classes.listContainer}><UsersList /> </div>),
        [tabsConstants.users] : (<div className={classes.listContainer}><UsersList /> </div>),
        [tabsConstants.wallet] : (<div><WalletComponent /></div>),

    }
    
    return tabsComponentMapping[`${selectedView}`]
  };

  const handeTabsChange = ( view : string)=> {
    setSelectedView( _ => view)
  }


  return (
    <div className={classes.container}>
      <header className={classes.header}>
      {headerButtonsArray.map((button) => (
          <Button
            key={button.view}
            variant={selectedView === button.view ?ButtonVariantEnum.primary : button.variant   }
            onClick={() => handeTabsChange(button.view)}
            size={ButtonTextSizeEnum.sm}
            text={button.text}
            isActive = {selectedView === button.view}
          />
        ))}
      </header>
      <div className={classes.body}>
        {renderBody(selectedView)}
      </div>
    </div>
  );
};

export default AdminPanel;
