import React, { useState } from 'react';
import { Paper, Typography, Box, CircularProgress, Grid, TextField, Button } from '@mui/material';
import styles from './userDetail.module.scss';
import { AdminServices } from '../../../services/admin/AdminServices';
import { validateEmail } from '../../../utils/validation';

const UserDetail: React.FC = () => {
  const [userId, setUserId] = useState<string>('');
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userIdOrEmail, setUserIdOrEmail] = useState<string>('');

  const handleSearch = () => {
    if (userIdOrEmail.trim() === '') {
      setError('Please enter a user ID or email');
      return;
    }
    setLoading(true);
    setError(null);
    const fetchUser = validateEmail(userIdOrEmail)
      ? AdminServices.getUserByEmail(userIdOrEmail)
      : AdminServices.getUserById(userIdOrEmail);

    fetchUser
      .then(response => {
        setUser(response.response);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching user:', error);
        setError('User not found');
        setLoading(false);
      });
  };

  return (
    <div className={styles.userDetailContainer}>
      <Box p={2} mb={2}>
        <TextField
          label="User ID or Email"
          variant="outlined"
          value={userIdOrEmail}
          onChange={(e) => setUserIdOrEmail(e.target.value)}
          fullWidth
        />
        <Button variant="contained" color="primary" onClick={handleSearch} style={{ marginTop: '10px' }}>
          Search
        </Button>
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      )}

      {!loading && !user && !error && (
        <Typography variant="h6" align="center">
          Please search the user by typing their ID in the search box
        </Typography>
      )}

      {error && (
        <Typography variant="h6" align="center" color="error">
          {error}
        </Typography>
      )}

      {user && !loading && (
        <Paper className={styles.userDetailContainer}>
          <Typography variant="h5" gutterBottom>User Details</Typography>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}><Typography variant="body1"><strong>User ID:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1"> {user.userId}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Org ID:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1"> {user.organization.orgId}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Name:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1"> {user.name}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Organization Name:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1">{user.organization.name}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Phone Number:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1">{user.phoneNumber}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Role:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1">{user.role}</Typography></Grid>           
                <Grid item xs={6}><Typography variant="body1"><strong>Type:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1">{user.type}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Email:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1">{user.email}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Referral Code:</strong></Typography></Grid>
              <Grid item xs={6}><Typography variant="body1">{user.referralCode}</Typography></Grid>

              <Grid item xs={6}><Typography variant="body1"><strong>Status:</strong></Typography></Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{user.isActive ? 'Active' : 'Inactive'}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </div>
  );
};

export default UserDetail;
