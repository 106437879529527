import { LayoutProvider } from "./layout/LayoutProvider/LayoutProvider";
import { Routes, Route, useLocation } from 'react-router-dom'
import { routesData } from "./routing/routesData";
import { LayoutConsumer } from "./layout/LayoutConsumer/LayoutConsumer";

export const App = () => {
    const location = useLocation()
    return (
        <LayoutProvider>
            <Routes location={location}>
                {
                    routesData.map(route => {
                        const { path, Component, children, ...rest } = route;
                        return (
                            <Route
                                key={path}
                                path={path}
                                element={
                                    <LayoutConsumer
                                        {...rest}
                                        path={path}
                                    >
                                        <Component />
                                    </LayoutConsumer>
                                }
                            >
                                {
                                    children && children.map(child => {
                                        const { path, Component } = child;
                                        return (
                                            <Route
                                                key={path}
                                                path={path}
                                                element={<Component />}
                                            />
                                        )
                                    })
                                }
                            </Route>
                        )
                    })
                }
            </Routes>
        </LayoutProvider>
    );
}; 

// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Login from './pages/Auth/Login/Login';

// export const App = () => {
// const NotFound = () => <div>404 Page Not Found</div>;
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="*" element={<NotFound />} /> // This will catch any undefined routes
//       </Routes>
//     </Router>
//   );
// };
