import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import AdminForm from "../../../components/AdminForms/AdminForm";
import ScreenForm from "../../../components/ScreenForms";
import { formTabs } from "../constans";
import VideoUploadAndPlayer from "../../../components/VideoUploaderForm";


const FormSwitcher: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(formTabs.USER);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="form switcher">
        <Tab label="Admin Form" />
        <Tab label="Screen Form" />
      </Tabs>
      <Box>
        {selectedTab === formTabs.USER ? <AdminForm /> : <ScreenForm />}
        {/* <VideoUploadAndPlayer /> */}
      </Box>
    </Box>
  );
};

export default FormSwitcher;
