import { all, call, put, takeLatest } from "redux-saga/effects";
import {
    actionGetMediaStream,
    actionGetMediaStreamFailed,
    actionGetMediaStreamSuccess,
} from "./media.reducer";
import { auth } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";

const fetchWrapper = async (url: string, customHeaders: Record<string, string> = {}) => {
    const currentUser = auth.currentUser;
    const clientId = process.env.REACT_APP_CLIENT_ID ?? 'default_client_id';
    const requestId = uuidv4();


    if (currentUser) {
        const access_token = await currentUser.getIdToken();
        const headers = {
            Authorization: `Bearer ${access_token}`,
            ClientId: clientId, // Add ClientId from .env
            RequestId: requestId,
            ...customHeaders, // Include any additional headers passed in
        };

        return fetch(url, { headers })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                return response.blob().then((blob) => ({
                    blob,
                    statusText: response.statusText,
                }));
            });
    } else {
        throw new Error("User not authenticated");
    }
};

// Saga to fetch video URL
function* fetchVideoUrl(payload: { orgId: string; id: string }) {
    try {
        const url = `${process.env.REACT_APP_GATEWAY_URL}/public/media/stream/org/${payload.orgId}/media/${payload.id}`;

        const { blob } = yield call(fetchWrapper, url);

        const urlCreator = window.URL || window.webkitURL;
        const videoUrl = urlCreator.createObjectURL(blob);
        return videoUrl;
    } catch (error) {
        console.error("Error fetching video URL:", error);
        return null;
    }
}
function* fetchMediaSaga({ payload }: any): any {
    try {
        const videoUrl = yield call(fetchVideoUrl, payload);

        if (videoUrl) {
            yield put(actionGetMediaStreamSuccess(videoUrl));
        } else {
            yield put(actionGetMediaStreamFailed());
        }
    } catch (error) {
        console.error("Error in fetchMediaSaga:", error);
        yield put(actionGetMediaStreamFailed());
    }
}

export function* mediaWatcher() {
    yield all([
        takeLatest(actionGetMediaStream.type, fetchMediaSaga),
    ]);
}
