import { TextVariantsEnum } from "../Text";

export type TButtonProps =  {
    text?: string;
    variant?: ButtonVariantEnum;
    size?: 'sm' | 'md' | 'lg';
    disabled?: boolean;
    onClick: () => void;
    loading?: boolean;
    className?: string;
    width?: string | number;
    height?: string | number;
    icon?: string;
    iconSize?: number;
    iconColor?: string;
    iconRotation?: '0'| '90' | '180' | '270'
    isActive?: boolean;
}

export enum ButtonVariantEnum {
    primary = 'primary',
    secondary = 'secondary',
    ternary = 'ternary'
}

export enum ButtonTextSizeEnum {
   sm = 'sm',
   md = 'md',
   lg = 'lg'
}