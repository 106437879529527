 export const routes = {
    signup: `/signup`,
    login:'/login',
     home: '/',
    overview: '/overview',
    media: '/media',
    campaign: '/campaign',
    createCampaign: '/create-campaign',
    admin:'/admin',
    screenId:"/screens/:id"
}