import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { TextField, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button as MuiButton, Typography, Box, Select, MenuItem, FormControl, InputLabel, CircularProgress, Backdrop } from "@mui/material";
import classes from "./adminPanelForm.module.scss";
import Button from "../Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../Button/button.types";
import { AdminServices } from "../../services/admin/AdminServices";

type FormValues = {
  name: string;
  orgName: string;
  phoneNumber: string;
  email: string;
  referralCode: string;
  orgType: string;
  type: string;
  role: string;
};

const AdminForm: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [userData, setUserData] = useState<{ userId: string; password: string } | null>(null);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<FormValues>();

  const onSubmit = handleSubmit((data) => {
    const parseData = {
      email: data.email,
      name: data.name,
      type: data.type,
      organization: {
        name: data.orgName,
        type: data.orgType
      },
      role: data.role
    };

    setLoading(true);
    AdminServices.registerUser(parseData)
      .then(response => {
        setLoading(false);
        if (response?.response?.status === "SUCCESS") {
          setUserData({
            userId: response.response.userId,
            password: response.response.password,
          });
          setModalOpen(true);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error registering user:', error);
      });
  });

  const handleCloseModal = () => {
    setModalOpen(false);
    setUserData(null);
  };

  return (
    <div className={classes.container}>
      <h2>Registration Form</h2>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              {...register("name", { required: "Name is required" })}
              label="Name"
              variant="outlined"
              fullWidth
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("orgName", { required: "Organization Name is required" })}
              label="Organization Name"
              variant="outlined"
              fullWidth
              error={!!errors.orgName}
              helperText={errors.orgName && errors.orgName.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("orgType", { required: "Organization Type is required" })}
              label="Organization Type"
              variant="outlined"
              fullWidth
              error={!!errors.orgType}
              helperText={errors.orgType && errors.orgType.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("phoneNumber", { required: "Phone Number is required" })}
              label="Phone Number"
              variant="outlined"
              fullWidth
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber && errors.phoneNumber.message}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address"
                }
              })}
              label="Email"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email && errors.email.message}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                label="Type"
                defaultValue="CUSTOMER"
                {...register("type", { required: "Type is required" })}
              >
                <MenuItem value="CUSTOMER">Customer</MenuItem>
                <MenuItem value="MERCHANT">Merchant</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                label="Role"
                defaultValue="ADMIN"
                {...register("role", { required: "Role is required" })}
              >
                <MenuItem value="ADMIN">Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button variant={ButtonVariantEnum.primary} size={ButtonTextSizeEnum.lg} text="Register" onClick={onSubmit} />
              <Button variant={ButtonVariantEnum.ternary} onClick={() => {}} size={ButtonTextSizeEnum.lg} text="Cancel" />
            </div>
          </Grid>
        </Grid>
      </form>

      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle style={{ backgroundColor: "#6938EF", color: "#fff" }}>
          <Typography variant="h5" component="div" align="center">
            Registration Successful
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {userData && (
            <Box p={2} textAlign="center">
              <Typography variant="h6" style={{ color: "#6938EF" }}>
                Your account has been created successfully!
              </Typography>
              <Typography variant="body1" mt={2}>
                <strong>User ID:</strong> {userData.userId}
              </Typography>
              <Typography variant="body1" mt={2}>
                <strong>Password:</strong> {userData.password}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariantEnum.primary} size={ButtonTextSizeEnum.sm} text="Ok" onClick={handleCloseModal} width={80} />
        </DialogActions>
      </Dialog>

      <Backdrop open={loading} style={{ zIndex: 1300, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default AdminForm;
