import { api } from "..";

export const WalletServices = {
    creditWallet: (payload: { orgId: string, amount: string, userId: string }) =>
        api.post(`/admin/wallet/credit`, payload).then((data) => data?.data),

    debitWallet: (payload: { orgId: string, amount: string, userId: string }) =>
        api.post(`/admin/wallet/debit`, payload).then((data) => data?.data),

    getWalletBalance: (orgId: string) =>
        api.get(`/admin/wallet/balance/org/${orgId}`).then((data) => data?.data),

    getWalletHistory: (orgId: string, page: number) =>
        api.get(`/admin/wallet/history/org/${orgId}/page/${page}`).then((data) => data?.data),
};
